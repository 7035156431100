/* @import url(//db.onlinewebfonts.com/c/1c45e28f8e86cc89876f003b953cc3e9?family=SF+Pro+Text);
@import url(//db.onlinewebfonts.com/c/1bd3de6fcdd9ba7750e5a353dc6ef6e3?family=SF+Pro+Text);
@import url(//db.onlinewebfonts.com/c/28adc6c6fd652123c3e173389a8be17f?family=SF+Pro+Text); */
@font-face {
  font-family: 'Magistral';
  src: local('Magistral'), url(./assets/font/Magistral-ExtraBold.ttf) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Magistral';
  src: local('Magistral'), url(./assets/font/Magistral-Bold.ttf) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Magistral';
  src: local('Magistral'), url(./assets/font/Magistral-Medium.ttf) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Magistral';
  src: local('Magistral'), url(./assets/font/Magistral-Medium.ttf) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Magistral';
  src: local('Magistral'), url(./assets/font/Magistral-Book.ttf) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
  font-weight: 400;
  font-style: normal;
}


body {
  margin: 0;
  padding: 0;
  font-family: "Magistral" !important;
  background-color: #f6f6f6 !important;
}
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
/* header style */
.header {
  height: 56px;
  padding: 0;
  display: flex;
  align-items: center;
  border-bottom: none !important;
}
.header .app-logo {
  display: inline-block;
}
.header .navbar {
  padding: 0;
}
.header .navbar-light .navbar-nav .nav-link {
  color: #74797e;
  font-family: "Magistral";
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 24px;
  margin-right: 10px;
  padding: 20px 15px;
  white-space: nowrap;
  height: 32px;
}
.header .navbar-light .navbar-nav .nav-link.active {
  color: #FFF;
  height: 32px;
  border-radius: 5px;
  background-color: #2B9A99 !important;
}
.header .navbar-light .navbar-nav .nav-link svg {
  color: #74797e;
  fill: #74797e;
  stroke: #74797e;
  margin-right: 10px;
}
.header .navbar-light .navbar-nav .nav-link.active svg {
  color: #FFF;
  fill: #FFF;
  stroke: #FFF;
}
.header .navbar-right .nav-item {
  padding: 0;
}
.header .navbar-right .nav-item .nav-link {
  padding: 0;
  margin: 0;
}
.header .navbar-right .nav-item .dropdown-toggle:after {
  content: "\e92d";
  font-family: "feather";
  border: none;
  height: auto;
  width: auto;
  color: #74797e;
}
.dropdown-menu {
  border-radius: 12px !important;
  background-color: #ffffff !important;
  box-shadow: 0 2px 48px 0 rgba(39, 68, 141, 0.1) !important;
  border: none !important;
  padding: 0 !important;
}
.dropdown-menu .dropdown-item {
  color: #000;
  padding: 12px;
  font-size: 14px;
}
.dropdown-menu .dropdown-divider {
  margin: 0;
}
.dropdown-menu .dropdown-item.active {
  padding: 12px;
  height: auto !important;
  background-color: #f8f9fa !important;
}
.dropdown-item:active,
.dropdown-item.active {
  color: #000 !important;
  background-color: #f8f9fa !important;
}
.dropdown-menu .dropdown-item:hover:first-child {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
}
.dropdown-menu .dropdown-item:hover:last-child {
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}
.header .navbar-right .nav-item .dropdown-menu {
  left: auto;
  right: 0;
  /* height: 145.5px; */
  width: 224px;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 2px 48px 0 rgba(39, 68, 141, 0.1);
  border: none;
  margin-top: 10px !important;
}
.header .navbar-right .nav-item .dropdown-menu .dropdown-item {
  color: #000;
  font-family: "Magistral";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  display: flex;
  align-items: center;
}
.header .navbar-right .nav-item .dropdown-menu .dropdown-item.active,
.header .navbar-right .nav-item .dropdown-menu .dropdown-item:active {
  color: #000;
  /* height: 32px; */
  border-radius: 5px;
  background-color: #f6f6f6;
}
.header .navbar-right .nav-item .dropdown-menu .dropdown-item svg {
  color: #979797;
  margin-right: 10px;
}
.header .navbar-right .nav-item .dropdown-menu .dropdown-item.active svg,
.header .navbar-right .nav-item .dropdown-menu .dropdown-item:active svg {
  color: #000;
}
.header .navbar-light .navbar-toggler {
  border-color: transparent;
  padding: 0;
}
.header .dropdown-toggle.nav-link {
  padding: 0 !important;
}
.header .navbar-light .navbar-toggler-icon {
  background-image: url("/images/menu.svg");
  width: 16px;
  height: 12px;
}
.header .app-logo {
  display: none;
}
/* ************ */
.log-id {
  width: 1% !important;
}
.log-id div {
  width: 90px;
}
.log-message {
  white-space: pre-line !important;
  min-width: 300px;
  width: 55% !important;
}
.log-message div {
  width: 50vw !important;
}
.log-date {
  white-space: pre-line !important;
  min-width: 137px;
  width: 5% !important;
  padding: 5px 0 !important;
}
.log-date time {
  min-width: 137px !important;
}
.user-name {
  white-space: pre-line !important;
  min-width: 137px;
  width: 16% !important;
}
/* page style */
.page .alert-success {
  color: #fff;
  background-color: #6fd758;
}
.page .page-content {
  margin-bottom: 0 !important;
  margin-top: 30px !important;
}
.page .page-header {
  margin: 30px 0 30px;
}
.page-header .page-title, .page-header-title {
  color: #000000;
  font-family: "Magistral";
  font-size: 25px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 32px;
}

/* ****** */
/* card style */
.page .card {
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 2px 48px 0 rgba(39, 68, 141, 0.1);
  border: none;
  margin-bottom: 0px !important;
}
.page .card.charge-point-card {
  min-height: 648px;
}
.page .card .card-header {
  padding: 20px;
  border-bottom: none;
}
.page .card .card-title {
  color: #000;
  font-family: "Magistral";
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 24px;
}
.page .card .card-body {
  padding: 20px;
  color: #171717;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}
.page .card.fixed-card {
  min-height: 380px;
}
.page .card.user-fixed-card {
  min-height: 489px;
}
.page .card .card-body .c3 {
  margin-bottom: -8px;
}
.page .card .card-body .h1 {
  color: #000;
  font-family: "Magistral";
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;
}
.page .card .card-body .subheader {
  color: #74797e;
  font-family: "Magistral";
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 16px;
}
.page .card .card-body .detail {
  color: #74797e;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
}
.page .card .card-body .text-green {
  color: #000000 !important;
}
.page .card .card-body .text-red {
  color: #ff4747 !important;
}
.page .card .card-body .bg-green {
  background-color: #000000 !important;
}
.page .card .card-body .bg-red {
  background-color: #ff4747 !important;
}
.page .loading {
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
/* dropdown style*/
.card .dropdown.nav-item {
  padding: 0;
}
.cp-new-body {
  padding: 20px;
}
.mtop-3 {
  margin-top: 30px;
}
.card .dropdown-toggle.nav-link {
  color: #171717;
  padding: 0;
}
.card .dropdown-toggle:after {
  content: "\e92d";
  font-family: "feather";
  border: none;
  height: auto;
  color: #e5e9ef;
  width: auto;
}
/* *** */
/* table style */
.datatable {
  border-bottom: solid 1px rgba(0, 40, 100, 0.12);
}
.card .table thead th {
  color: #000000;
  font-family: "Magistral";
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 16px;
  background: #f6f6f6;
  text-transform: capitalize;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: none;
  white-space: nowrap;
}
.card .card-table tr td {
  font-family: "Magistral";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  color: #171717;
}

.card .card-table tr td:first-child {
  color: #000;
}
.card .card-table tr td:first-child.fit-content {
  width: 260px;
}
.card .card-table tr td:last-child {
  width: 1px;
  white-space: nowrap;
}
.card .card-table tr td:first-child,
.card .card-table tr th:first-child {
  padding-left: 20px;
}
.card .card-table tr td:last-child,
.card .card-table tr th:last-child {
  padding-right: 20px;
}
/* *** */
/* button style */

.card .btn-secondary,
.page .btn-secondary {
  box-sizing: border-box;
  border: 1px solid #e5e9ef;
  border-radius: 5px;
  color: #000;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  background: transparent;
  box-shadow: none;
}
.page .btn-barlows {
  border-radius: 5px;
  background-color: #2B9A99;
  color: #ffffff;
  font-family: "Magistral";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  font-weight: 400;
  border: none;
  padding: 5px 10px;
  gap: 10px;
  cursor: pointer;

  &:focus {
    border: none;
    outline: none;
  }
}
.page .evaa-btn {
  margin-top: 30px;
}
.card .btn-upload,
.page .btn-upload {
  box-sizing: border-box;
  border: 1px dashed #e5e9ef;
  background-color: #fafbfb;
  border-radius: 5px;
  color: #000;
  font-weight: 400;
  font-size: 14px;
  box-shadow: none;
  background-image: url("/images/cloud-upload.svg") !important;
  background-repeat: no-repeat;
  background-position-x: 0.75rem;
  background-position-y: center;
  padding-left: 2.5rem;
}
/* **** */

/* form element style */

.page .input-normal {
  font-family: 'Magistral';
  font-size: 14px;
  font-weight: 400;
  height: 60px !important;
  border-radius: 6px;
  line-height: 21px;
  color: #2E2E2E;
}

.page .form-group .form-label {
  font-family: 'Magistral';
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  color: #74797E;
}

/* ****** */

/* pagination style */
.pagination-barlows {
  display: flex;
  padding: 0.75rem 20px;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: #171717;
  font-family: "Magistral";
  letter-spacing: 0;
  line-height: 24px;
}

.modal-backdrop {
  background-color: rgba(243, 243, 245, 0.5) !important;
}
.delete-footer {
  position: absolute;
  bottom: 20px;
  width: 100%;
}

/* **************** */
/* form select style */

@supports (-moz-appearance: none) {
  select > option {
    /* font: -moz-pull-down-menu; */
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  }
}
.page form .form-control {
  border-radius: 5px;
  font-size: 14px;
  height: 40px;
  color: #171717;
}
.page .mt-form {
  margin-top: 20px;
}
.page form .input-group-text {
  font-size: 14px;
  color: #171717;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #f6f6f6;
}
.page form .form-select {
  height: 40px !important;
}
.page .form-select {
  display: block;
  width: 100%;
  height: 32px !important;
  padding: 0 1.75rem 0 0.75rem;
  font-weight: 400;
  color: #171717;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%2895, 102, 110, 0.44%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 16px 12px;
  border-radius: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 1px solid #e5e9ef;
  font-family: "Magistral";
  font-size: 14px;
  letter-spacing: 0;
}
.form-select:focus {
  border-color: #7aade9;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(32, 107, 196, 0.25);
}
.form-select:focus::-ms-value {
  color: #212b36;
  background-color: #ffffff;
}
.form-select[multiple],
.form-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  color: #a8aeb7;
  background-color: #f1f3f8;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212b36;
}

.page .form-control::-webkit-input-placeholder {
  color: #171717;
}
.page .form-control::-moz-placeholder {
  color: #171717;
}
.page .form-control:-ms-input-placeholder {
  color: #171717;
}
.page .form-control::-ms-input-placeholder {
  color: #171717;
}
.page .form-control::placeholder {
  color: #171717;
}
.form-control.notes-area {
  height: 156px !important;
  font-size: 14px;
  color: #171717;
}
.form-control:focus {
  color: #171717 !important;
}
/* ***** */
/* ******* */
/* chatrs style*/
.charts-label {
  color: #000 !important;
}
/* *** */
.container .page-footer {
  margin-top: 0 !important;
}
.flex {
  display: flex !important;
  align-items: center !important;
  display: -webkit-flex !important;
  -webkit-align-items: center !important;
}
.login-page {
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 2px 48px 0 rgba(39, 68, 141, 0.1);
  margin: auto;
}
.login-page .form-wrapper {
  z-index: 1;
}
.login-page .row {
  height: 100%;
}
.login-page .welcome-title {
  color: #000;
  font-family: "Magistral";
  font-size: 25px;
  font-weight: 700;
  line-height: 1.2em;
  margin-bottom: 20px;
}
.login-page .welcome-title.create-password {
  line-height: 48px;
}
.login-page .label-title {
  color: #4D4D4D;
  font-family: "Magistral";
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 0;
}
.login-page .form-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}
.login-page .form-body form {
  margin: 40px 0;
}
.login-page .form-body form .form-group {
  margin-bottom: 20px;
}
.login-page .form-body form .form-control {
  box-sizing: border-box;
  height: 44px;
  border: 1px solid #e5e9ef;
  border-radius: 5px;
  color: #171717;
  font-family: "Magistral";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
}
.login-page .form-body form .form-control.is-invalid {
  border-color: #cd201f;
}
.login-page .form-body form .btn-barlows {
  height: 44px;
  border-radius: 5px;
  background-color: #2B9A99;
  color: #ffffff;
  font-family: "Magistral";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  font-weight: 700;
}
.login-page .forgot-text {
  color: #2B9A99 !important;
  font-family: "Magistral";
  font-weight: 400 !important;
}
.login-page .form-body .nav-link {
  color: #000;
  font-family: "Magistral";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  padding: 0;
}
.login-page .form-body .nav-link:hover {
  color: #000;
}

.login-page .form-body .login-image {
  width: 116px;
}

.login-page .form-body .mm-logo-title {
  color: #2B9A99;
  font-family: 'Magistral';
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 5px;
  margin-top: 20px;
}

.login-page .form-body .mm-logo-subtitle {
  color: #2B9A99;
  text-align: center;
  font-family: 'Magistral';
  font-size: 20px;
  font-style: italic;
  font-weight: 300;
  line-height: normal;
}


.page-footer {
  height: auto;
  position: relative;
  bottom: 0;
  margin-bottom: 20px;
}
.page-footer .footer-body {
  display: flex;
  margin: auto;
  justify-content: space-between;
  color: #74797e;
  font-family: "Magistral";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  height: 100%;
  align-items: center;
}
.page-footer .footer-body a {
  color: inherit;
}
.ev-logo {
  background-image: url("/images/login.svg");
  display: flex;
  /* width: 108.3333333333%; */
  height: 100%;
  background-repeat: no-repeat;
  /* background-size: 680px 528px; */
  /* margin-left: -8.33333333%; */
  /* background-position-y: bottom; */
}
.ev-logo.password-logo {
  background-image: url("/images/mm_logo.png");
  background-position-y: 50%;
  width: 100%;
  margin-left: 0;
}
.ev-logo.verified-logo {
  background-image: url("/images/mm_logo.png");
  background-size: 313px 225px;
  width: 100%;
  margin-left: 0;
  background-position-y: 50%;
  background-position-x: 50%;
}

.cursor-pointer {
  cursor: pointer !important;
}
.text-decoration-underline {
  text-decoration: underline !important;
}

.details {
  color: #000;
  font-family: "Magistral";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
}
.details-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.details-card .card {
  height: 141px;
}
.details-card .card .card-body {
  padding-top: 20px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.price {
  color: #000 !important;
  width: auto !important;
}
.price_per_kwh input::-webkit-input-placeholder {
  color: #171717;
}
.price_per_kwh input::-moz-placeholder {
  color: #171717;
}
.price_per_kwh input:-ms-input-placeholder {
  color: #171717;
}
.price_per_kwh input::-ms-input-placeholder {
  color: #171717;
}
.price_per_kwh input::placeholder {
  color: #171717;
}
.price_per_kwh input {
  padding: 0;
  border: none;
  outline: none;
  color: #171717;
}
.form-control.price_per_kwh {
  display: flex;
  align-items: center;
}
.form-control.price_per_kwh:focus-within {
  background-color: #fff;
  border-color: #1991eb;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.25);
}

.card table tbody tr.down {
  background-color: rgba(255, 71, 71, 0.1);
}
.card table tbody tr.down td {
  color: #ff4747;
}
::placeholder {
  color: #979797 !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #979797 !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #979797 !important;
}
.alert-danger-chargebox {
  border-radius: 5px;
  background-color: #ff4747;
  color: #ffffff;
  font-family: "Magistral";
  font-size: 14px !important;
  letter-spacing: 0;
  line-height: 20px;
  display: flex;
  align-items: center;
}
.alert-danger-chargebox .fe-x-circle {
  font-size: 20px !important;
  top: auto !important;
}
select.react-daterange-picker__inputGroup__input {
  pointer-events: none;
}
.react-daterange-picker__inputGroup__input option {
  display: none !important;
}
.user-page {
  flex-direction: row;
  align-items: center;
}
.user-page .btn-outline-danger {
  color: #ff4747;
  border-color: #ff4747;
  border-radius: 5px;
  font-family: "Magistral";
  font-weight: 400;
  height: 32px;
  padding-top: 0;
  padding-bottom: 0;
}
.user-page .btn-outline-danger:hover {
  background-color: #ff4747;
  border-color: #ff4747;
  color: white;
}
.modal {
  background-color: rgba(0, 0, 0, 0.4);
}
.modal-dialog.delete-user {
  max-width: 380px;
}
.modal-dialog.delete-user .modal-content {
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 2px 48px 0 rgba(39, 68, 141, 0.1);
  border: none;
}
.modal-dialog.delete-user .notification-content {
  flex-direction: column;
  padding: 10px 30px 30px;
}
.modal-dialog.delete-user .notification-content .content-title {
  color: #000;
  font-size: 16px;
  font-family: "Magistral";
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 10px;
}
.modal-dialog.delete-user .notification-content .content-note {
  color: #74797e;
  font-size: 14px;
  font-family: "Magistral";
  text-align: center;
}

.react-daterange-picker__wrapper {
  display: none !important;
  /* display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  border: thin solid #e5e9ef;
  margin-right: 0.5rem !important;
  border-radius: 5px; */
}
.custom-datepicker .date-input {
  height: 32px;
  margin-right: 10px;
  border-radius: 5px;
  border: thin solid #e5e9ef;
  width: 230px;
  padding-left: 15px;
  padding-right: 15px;
  color: #171717;
  font-family: "Magistral";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  background-color: transparent !important;
  display: unset !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%2895, 102, 110, 0.44%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 16px 12px;
}
.custom-datepicker .react-daterange-picker__calendar {
  top: 20px !important;
  width: 250px;
}
.custom-datepicker .react-daterange-picker__calendar .react-calendar {
  border-radius: 12px;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
}
.custom-datepicker .react-daterange-picker__calendar .react-calendar .react-calendar__tile--rangeStart {
  position: relative;
  background: #e1f5d9;
  border-radius: 0;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
.custom-datepicker .react-daterange-picker__calendar .react-calendar .react-calendar__tile--rangeEnd {
  position: relative;
  background: #e1f5d9;
  border-radius: 0;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}
.custom-datepicker .react-daterange-picker__calendar .react-calendar .react-calendar__tile--rangeBothEnds {
  border-radius: 50%;
}
.custom-datepicker .react-daterange-picker__calendar .react-calendar .react-calendar__tile--rangeStart abbr,
.custom-datepicker .react-daterange-picker__calendar .react-calendar .react-calendar__tile--rangeEnd abbr {
  width: 34.56px !important;
  height: 34.56px !important;
  background: #000000;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
}

.navbar-right .user-info .dropdown-menu {
  padding: 0;
}
.navbar-right .user-info .dropdown-menu .dropdown-item {
  padding: 12px;
}
.navbar-right .user-info .dropdown-menu .dropdown-item.active {
  padding: 12px;
  height: auto !important;
}
.navbar-right .user-info .dropdown-menu .dropdown-divider {
  margin: 0;
}

.navbar-right .user-info span {
  font-family: "Magistral";
  font-weight: 400;
}

.cancel-subscription .modal-body {
  padding: 30px 0 10px;
}
.cancel-subscription .modal-body .close-icon {
  position: relative;
}
.cancel-subscription .modal-body .close-icon svg {
  position: absolute;
  top: -7px;
  right: 15px;
}
.dashboard-dropdown .nav-link {
  color: #74797e !important;
}

.card.dashboard-card {
  height: 100% !important;
}

.total-transaction-value {
  margin-top: 1.5rem;
}
.disputes-details {
  width: 20% !important;
  flex-basis: unset !important;
  flex-grow: unset !important;
  max-width: 20% !important;
}
.disputes-details .details {
  /* white-space: nowrap !important; */
  margin-bottom: 30px;
}
.transaction-details {
  width: 33% !important;
  flex-basis: unset !important;
  flex-grow: unset !important;
  max-width: 33% !important;
}
.transaction-details .details {
  /* white-space: nowrap !important; */
  margin-bottom: 30px;
}
.payment-header .nav-link svg {
  color: #74797e !important;
}
.payment-header .nav-link.active svg {
  color: #000 !important;
}

@media (max-width: 425px) {
  .login-page .form-body .login-image {
    width: 62px;
  }

  .login-page .form-body .mm-logo-title {
    font-size: 18px;
  }

  .login-page .form-body .mm-logo-subtitle {
    font-size: 12px;
  }

}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .page-options .d-sm-none {
    display: block !important;
  }
  .page-title .d-sm-block {
    display: none !important;
  }
  .page-title.w-100 {
    width: auto !important;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  /* .page-footer {
    margin-top: 46px;
  } */
  .login-page {
    height: 648px;
    width: 1320px;
  }
  #root .container {
    max-width: 1320px;
    padding: 0;
  }
}

@media (max-width: 1200px) {
  .login-page {
    height: 648px;
    width: calc(100% - 60px);
  }
  #root .container {
    max-width: calc(100% - 60px);
    padding: 0;
  }
  .navbar-right .user-info {
    max-width: 135px;
    text-align: center;
  }
  .navbar-right .user-info span {
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
}
/* Table style */
@media (max-width: 1024px) {
  .total-transaction-days {
    margin: 10px 0 10px 0;
  }
  .total-transaction-value {
    margin-top: 0;
  }
  .user-name {
    white-space: pre-line !important;
    min-width: 137px;
    width: 43% !important;
  }
  .login-page .form-body {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-top: 77px;
    justify-content: normal;
  }
  .login-page {
    height: 910px;
    width: 708px;
    margin-top: 30px;
  }
  .page .card.charge-point-card {
    min-height: 792px;
  }
  #root .container {
    max-width: 708px;
    padding: 0;
  }
  .container .col-lg-6 {
    flex: 1;
    max-width: 100%;
  }
  .header .navbar-light .navbar-nav .nav-link {
    margin-right: unset;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .header .navbar-light .navbar-nav .nav-link svg {
    display: none;
  }

  .login-page .row {
    flex-direction: column;
  }
  .login-page .row .col-lg-6 {
    flex: 1;
    max-width: 100%;
  }
  .login-page .col-lg-8 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ev-logo {
    width: 100%;
    background-size: 300px 300px;
    /* margin-left: 0; */
    background-position-x: 50%;
  }
  .ev-logo.password-logo {
    /* background-position-y: 130%; */
    width: 100%;
    /* margin-left: 0; */
    /* background-size: 549px 418px; */
    /* background-position-x: 150%; */
  }
  .ev-logo.verified-logo {
    background-size: 235px 169px;
    background-position-x: 50%;
  }
  .page-footer {
    height: auto;
    position: relative;
    bottom: 0;
    margin-bottom: 20px;
  }
  .page-footer .footer-body {
    align-items: center;
    height: 100%;
  }
  .page-options.reports {
    margin-left: 0 !important;
    width: 100%;
  }
  .page-options.reports .d-flex:last-child {
    flex: 1;
    justify-content: space-between;
  }
  .page-header .reports-title {
    margin-bottom: 20px !important;
  }
}
@media (max-width: 767px) {
  .total-transaction-days {
    margin: 0;
  }
  .total-transaction-value {
    margin-top: 1.5rem;
  }
  .transaction-details .details {
    /* white-space: nowrap !important; */
    margin-bottom: 20px;
  }
  .login-page {
    width: calc(100% - 60px);
  }
  #root .container {
    max-width: calc(100% - 60px);
    padding: 0;
  }
  .page-options .d-sm-none {
    display: block !important;
  }
  .page-title .d-sm-block {
    display: none !important;
  }
  .page-title.w-100 {
    width: auto !important;
  }
}
/* Mobile style */
@media (max-width: 711px) {
  .details {
    font-size: 12px;
  }
  .delete-footer {
    bottom: 10px;
  }
  .disputes-details {
    width: 50% !important;
    flex-basis: unset !important;
    flex-grow: unset !important;
    max-width: 50% !important;
  }
  .disputes-details .details {
    /* white-space: nowrap !important; */
    margin-bottom: 20px;
  }
  .transaction-details {
    width: 50% !important;
    flex-basis: unset !important;
    flex-grow: unset !important;
    max-width: 50% !important;
  }
  .page .page-content {
    margin-bottom: 0 !important;
    margin-top: 20px !important;
  }
  .payment-header .navbar svg {
    display: none !important;
  }
  .payment-header .nav-link,
  .payment-header .navbar-collapse {
    border-bottom: none !important;
  }
  .payment-header .nav-link.active {
    border-radius: 4.5px !important;
  }
  .cp-new-body {
    padding-top: 0 !important;
  }
  .mtop-3 {
    margin-top: 10px;
  }
  .mtm-20 {
    margin-top: -20px;
  }
  .page .card.charge-point-card {
    min-height: 630px;
  }
  .user-detail-content {
    padding-top: 0 !important;
  }
  .add-new-cp span {
    display: none;
  }
  .login-page .welcome-title.create-password {
    line-height: 40px;
  }

  .page .card .card-body .detail {
    font-size: 12px;
  }
  .page .mt-form {
    margin-top: 0px;
  }
  .page form .form-control {
    font-size: 12px;
  }
  .login-page {
    height: 732px;
    width: 335px;

    margin-top: 20px;
  }
  .new-payment-title span {
    display: none;
  }
  #root .container {
    max-width: 335px;
    padding: 0;
  }
  .header .app-logo1 {
    display: none;
  }
  .header .app-logo {
    display: block;
    background-image: url("/images/logo_icon.svg");
    background-repeat: no-repeat;
    mask-size: contain;
    width: 24px;
    height: 24px;
  }
  .header .navbar-brand {
    position: absolute;
    left: 46%;
  }
  .header .navbar-light .navbar-collapse {
    border-radius: 12px;
  }
  .header .navbar-light .navbar-nav .nav-link {
    font-size: 12px;
    border-bottom: solid 1px #e9ecef;
  }
  .user-info .nav-link {
    border-bottom: none !important;
  }

  .header .navbar-light .navbar-nav .nav-link.active {
    border-radius: 0;
  }
  .header .navbar-light .navbar-nav .nav-link svg {
    display: block;
    width: 22px;
    height: 14px;
  }
  .header .navbar-light .navbar-nav .nav-link.payments {
    border-bottom: none;
  }
  .header .navbar-light .navbar-nav .nav-link.payments svg {
    width: 23px;
    height: 23px;
  }
  .header .navbar-light .navbar-collapse {
    position: absolute;
    width: 100%;
    background: #fff;
    top: 43px;
    z-index: 10;
    box-shadow: 0 2px 48px 0 rgba(39, 68, 141, 0.1);
    padding: 0px;
    border-bottom: solid 1px #e9ecef;
  }
  .header .navbar-right .nav-item .dropdown-menu {
    position: absolute;
  }
  .page .page-header {
    margin: 20px 0 20px;
  }
  .page-header .page-title {
    font-size: 16px;
  }
  .page .card .card-body .subheader {
    font-size: 9px;
    line-height: 15px;
  }
  .card .dropdown-toggle.nav-link {
    font-size: 12px;
    line-height: 20px;
  }
  .page .card .card-body .h1 {
    font-size: 24px;
    line-height: 27px;
  }
  .page .card .card-body {
    font-size: 12px;
    line-height: 20px;
    /* padding-top: 0; */
  }
  .page .card .card-title {
    font-size: 14px;
    line-height: 22px;
  }
  .card .btn-secondary {
    font-size: 12px;
  }
  .card .table thead th {
    font-size: 9px;
    line-height: 16px;
  }
  .card .card-table tr td {
    font-size: 12px;
    line-height: 20px;
  }
  .dropdown-menu .dropdown-item {
    font-size: 12px;
  }
  .header .navbar-right .nav-item .dropdown-menu .dropdown-item {
    font-size: 12px;
    line-height: 20px;
  }
  .header .navbar-right .nav-item .dropdown-menu {
    width: auto;
    height: auto;
  }
  .login-page .col-lg-8 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .login-page .row {
    flex-direction: column;
  }
  .login-page .form-body {
    padding: 0 calc(0.75rem + 20px) !important;
    margin-top: 35px;
    /* margin-top: auto; */
    justify-content: center;
  }

  .login-page .form-body form {
    margin: 20px 0;
  }
  .login-page .form-body .nav-link {
    font-size: 12px;
  }
  .login-page .form-body form .form-group {
    margin-bottom: 10px;
  }
  .form-group {
    margin-bottom: 10px !important;
  }
  .ev-logo {
    width: 100%;
    /* background-size: 431px 335px; */
    /* margin-left: 0; */
    /* background-position-x: 0; */
  }
  .ev-logo.password-logo {
    width: 100%;
    background-size: 200px 200px;
    background-position-x: 50%;
  }
  .ev-logo.verified-logo {
    background-size: 195px 142px;
    background-position-x: 50%;
  }
  .page-footer {
    height: auto;
    position: relative;
    bottom: 0;
    margin-bottom: 20px;
  }
  .page-footer .footer-body {
    width: 335px;
    align-items: center;
    height: 100%;
    font-size: 12px;
  }
  .container .col-lg-6 {
    flex: auto;
    max-width: 100%;
  }
  .col.col-6.col-lg-6 {
    max-width: 50%;
  }
  .details-card .col-lg-6 {
    flex: 1 1 50%;
  }
  .form-control.notes-area {
    height: 108px !important;
    font-size: 12px;
  }
  .card .btn,
  .page .btn {
    font-size: 12px;
    min-width: auto;
  }
  .page .btn-barlows {
    height: 28px;
    font-size: 12px;
  }
  .page .evaa-btn {
    margin-top: 10px;
  }
  .page .form-select {
    height: 28px !important;
    font-size: 12px;
  }
  .page-options .d-sm-none {
    display: none !important;
  }
  .page-title .d-sm-block {
    display: block !important;
  }
  .page-title.w-100 {
    width: 100% !important;
  }
  .page .page-options {
    margin-left: inherit;
  }
  .card .card-table tr td.mobile-locations {
    color: #000;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 20px;
  }
  .pagination-barlows {
    font-size: 12px;
  }
  .card .card-table tr td,
  .card .table thead th {
    white-space: nowrap;
  }
  .page .page-header {
    justify-content: space-between;
    margin: 20px 0 20px;
  }
  .mobile-hide {
    display: none;
  }
  .padding-sm-0 {
    padding-bottom: 0 !important;
  }
  .user-page {
    flex-direction: column;
    align-items: baseline !important;
  }
  .user-page > span {
    margin-left: 0 !important ;
    margin-top: 10px;
  }
  .modal-dialog.delete-user {
    max-width: 335px;
    margin: auto;
  }
  .modal-dialog.delete-user .notification-content {
    padding: 5px 20px 20px;
  }
  .modal-dialog.delete-user .notification-content .content-title {
    font-size: 14px;
  }
  .modal-dialog.delete-user .notification-content .content-note {
    font-size: 12px;
  }
  .page-options.reports {
    flex-direction: column;
  }
  .page-options.reports .d-flex:first-child {
    margin-bottom: 10px;
  }
  .custom-datepicker .date-input {
    height: 28px;
    font-size: 12px;
  }
  .page-header .reports-title {
    margin-bottom: 10px !important;
  }
}

.get-payments {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 650px;
  flex-direction: column;
}
.get-payments .details {
  margin: 20px 0;
  max-width: 680px;
  text-align: center;
}
.get-payments .title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}
.header.payment-header {
  border-top: 1px solid #f3f3f5;
}
@media (max-width: 711px) {
  .header.header.payment-header .navbar-light .navbar-collapse {
    position: initial;
    background: transparent;
    padding: 0;
    box-shadow: none;
  }
  .payment-header .nav-link:last-child {
    margin-left: calc(20px - 0.5rem);
  }
}

@media (min-width: 712px) and (max-width: 1024px) {
  .card .card-body.total-transactions .d-flex {
    display: initial !important;
  }
}


#gui {
  display: none !important;
}

.measure-saved-badge {
  display: flex;
  background-color: #DAF8EC;
  color: #0BC64B;
  border-radius: 8px;
  position: absolute;
  top: 1rem;
  right: 1rem;
  justify-content: center;
  gap: 1rem;
  padding: 0.5rem 1rem;
}

.default-font-color {
  color: #2B9A99 !important;
}


.measure-control-btn {
  background-color: #2B9A99;
  font-family: "Magistral";
  font-size: 16px;
  font-weight: 400;
  color: white;
  min-height: 32px;
  min-width: 32px !important;
  max-height: 32px;
}

.measure-control-btn.selected {
  background-color: #000000;
}

.icon-button {
  max-height: 32px;
  max-width: 32px;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.ratio-buttons {
  bottom: 0px !important;
  left: 3.5rem !important;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 10px;
}


.measuer-card-body {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.btn-switch .custom-switch-input:checked ~ .custom-switch-indicator {
  background-color: #2B9A99 !important;
}

.btn-switch .custom-switch-input:focus ~ .custom-switch-indicator {
  border-color: #FFFFFF !important;
  box-shadow: 0 0 0 2px rgba(43, 154, 153, 0.2) !important;
}


.custom-switch-input:checked ~ .custom-switch-indicator {
  background-color: #2B9A99 !important;
}

.custom-switch-input:focus ~ .custom-switch-indicator {
  border-color: #2B9A99 !important;
  box-shadow: 0 0 0 2px rgba(43, 154, 153, 0.2) !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.guide-box {

  .scena-manager {
    position: absolute;
  }

  .scena-manager.scena-horizontal {
    height: 30px !important;
  }

  .scena-manager.scena-vertical {
    width: 30px !important;
  }

  .guide-dot {
    background-color: #59d500;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    position: absolute;
    z-index: 3000;
  }
}


.mb-300 {
  margin-bottom: 300px !important;
}

.checking-block {
    width: 100px;
    height: 50px;
    border: 1px solid yellow;
}


/* Product page */
.imag-tile-container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 30px;
}

.imag-tile-container .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: 'space-between';
    align-items: center;
    gap: 30px;
}

.imag-tile-container .wrapper .image-tile {
    width: 150px;
    height: 150px;
    background-color: #F3F3F5;
    padding: 30px;
}

.imag-tile-container .wrapper .image-tile img {
    width: 100%;
    height: 100%;
}

.imag-tile-container .wrapper .add {
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-view-container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 30px;
}

.image-view-container .image-tile {
    width: 150px;
    height: 150px;
    background-color: #F3F3F5;
    padding: 30px;
}

.measurement-tile-header, .measurement-tile-body{
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
}

.gap-13 {
    gap: 13px;
}

.hidden {
    display: none;
}

@media (min-width: 768px) {
    .imag-tile-container {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

.line-button {
  position: relative;
  border-bottom: 1px solid #E3E3E6;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.line-button button {
  position: absolute;
  top: -15px;
  background-color: white !important;
}

.v-align {
  display: flex;
  align-items: center;
}

.form-control-plaintext {
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px !important;
}

.text-wrap {
  text-wrap: wrap;
}

.mt-13 {
    margin-top: 13px;
}

.justify-between {
  display: flex;
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

.custom-row td svg.add-custom {
  display: none;
}

.custom-row:hover td svg.add-custom {
  display: block;
}

button.btn-remove {
  font-size: 14px !important;
}



.add-tag .custom-select {
  border: 1px solid #B3B3B3;
  border-radius: 8px;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  height: 60px !important;
  padding-left: 20px;
}

.w-full {
  width: 100% !important;
}

.add-tag .bottom {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.add-tag .bottom button {
  padding-left: 30px;
  padding-right: 30px;
}

.add-tag .bottom .btn-add-tag {
  border: 1px solid #2B9A99;
  background-color: transparent;
  color: #2B9A99;
}

.add-tag .bottom .btn-add-tag:hover {
  background-color: rgba(43, 154, 153, 0.1);
}

.btn-back-measure {
  border: 1px solid #2B9A99 !important;
  background-color: transparent;
  color: #2B9A99;
}

.btn-error {
  border: 1px solid #F51932 !important;
  background-color: transparent;
  color: #F51932;
  min-width: 162px !important;
  max-height: 32px;
  line-height: normal !important;
}

.d-details {
  display: grid;
  gap: 10px; /* Adjust the gap between grid items as needed */
}

@media (max-width: 375px) {
  .d-details {
    grid-template-columns: repeat(2, 1fr); /* 2 items per row */
  }
}

@media (min-width: 376px) and (max-width: 768px) {
  .d-details {
    grid-template-columns: repeat(3, 1fr); /* 3 items per row */
  }
}

@media (min-width: 769px) {
  .d-details {
    grid-template-columns: repeat(6, 1fr); /* 6 items per row */
  }
}

.picture-container {
  display: flex;
  flex-direction: row !important;
}


/* product search page */

.product-card-wrapper {
  width: 100%;
  height: 198px;
  padding: 10px 20px;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
}

.product-card-wrapper.selected {
  border: 2px solid #2B9A99;
}

.product-card-wrapper img {
  height: 113px;
  width: 100%;
  object-fit: contain;
}

.product-card-wrapper .title {
  color: #B3B3B3;
  font-family: 'Magistral';
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.product-card-wrapper .content {
  font-family: 'Magistral';
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.product-card-wrapper svg {
  position: absolute;
  right: 8px;
  top: 8px;
  display: none;
}

.product-card-wrapper.selected svg {
  display: block;
}

@media (max-width: 425px) {
  .product-card-wrapper {
    padding: 10px 5px;
  }
}


.dot-container {
  display: flex;
  gap: 5px;
  align-items: center;
}

.gap-30 {
  gap: 30px;
}

.dot-container .dot {
  height: 8px;
  width: 8px;
  border-radius: 4px;
  border-color: #B3B3B3;
  border-width: 2px;
  border-style: solid;
}

.dot-container .dot.active {
  width: 16px;
  border-width: 0px;
  background-color: #2B9A99;
}

.pricing-table-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pricing-table-container .pricing-table-wrapper {
  width: 100%;
  height: 100%;
  max-width: 1180px;
  max-height: 680px;
  background-color: white;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pricing-table-container .title {
  font-weight: 700;
  color: #4D4D4D;
  font-size: 25px;
  line-height: 32px;
  padding-left: 100px;
}

.pricing-table-container .content {
  color: #4D4D4D;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  padding-left: 100px;
  padding-bottom: 60px;
}